// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-js": () => import("./../../../src/pages/make.js" /* webpackChunkName: "component---src-pages-make-js" */),
  "component---src-pages-make-the-illuminated-manuscript-js": () => import("./../../../src/pages/make/the_illuminated_manuscript.js" /* webpackChunkName: "component---src-pages-make-the-illuminated-manuscript-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogposting-js": () => import("./../../../src/templates/blogposting.js" /* webpackChunkName: "component---src-templates-blogposting-js" */),
  "component---src-templates-bookpage-js": () => import("./../../../src/templates/bookpage.js" /* webpackChunkName: "component---src-templates-bookpage-js" */),
  "component---src-templates-build-js": () => import("./../../../src/templates/build.js" /* webpackChunkName: "component---src-templates-build-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

